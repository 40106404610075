
const config = {
    name: 'Ana & Ivan',
    date: '20.07.2024.',
    subdomain: 'anaiivan20072024',
    password: 'ana4838',
    maxImage: 5
  };

  // PROMIJENI INDEX TITLE
  
  export default config;
  