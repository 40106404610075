import React from "react";
import { Instagram } from "lucide-react";

function PoweredBy({ currentPhotos = 0 }) {
  const position = currentPhotos > 0 ? "bottom-12" : "bottom-4";

  return (
    <div className={`fixed ${position} left-4 flex flex-row items-center`}>
      <a
        href="https://www.instagram.com/personalizirani.poklon/"
        target="_blank"
        className="flex items-center"
      >
        POWERED BY:
        <Instagram className="ml-2 mr-1 h-4 w-4" /> @personalizirani.poklon
      </a>
    </div>
  );
}

export default PoweredBy;
