import React, { useState } from "react";
import { InfoIcon, X } from "lucide-react";

function InfoModal() {
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="z-20">
      <div className="absolute top-4 left-4">
        <button onClick={openModal}>
          <InfoIcon className="text-white" />
        </button>
      </div>

        <div className={`top-0 left-0 fix w-full h-[100dvh] flex absolute justify-center z-20 transition-opacity duration-500 ${modalOpen ? "opacity-100 flex" : "opacity-0 hidden"}`}>
             <button
                onClick={closeModal}
                className="fixed right-4 top-4"
              >
          <X  className=" text-gray-800 z-50"/>
              </button>
          <div className="bg-white text-gray-800 p-8 rounded shadow-lg max-w-md flex flex-col gap-6 overflow-scroll ">
            <h2 className="text-2xl font-semibold">Upute:</h2>

            <div>
              <h3 className="text-lg font-semibold">Korak 1: Unos Imena</h3>
              <p>Upišite ime i prezime kako bi vaše fotografije bile povezane s vama.</p>
              <p>Kliknite na gumb "Start" </p>
            </div>

            <div>
              <h3 className="text-lg font-semibold">Korak 2: Dodavanje fotografija</h3>
              <ol className="list-decimal list-inside ml-4">
                <li>Pronađite ikonu plusa (+) u sredini zaslona.</li>
                <li>Dodirnite ikonu plusa kako biste napravili fotografiju.</li>
                <li>Napravite fotografiju s vašim uređajem</li>
                <li>Potvrdite fotografiju kako biste je učitali u aplikaciju.</li>
                <li>Ponovite ovaj postupak za svih pet fotografija.</li>
              </ol>
            </div>

            
            <div>
              <h3 className="text-lg font-semibold">Korak 3: Učitavanje fotografije</h3>
              <ol className="list-decimal list-inside ml-4">
              <li>Proces učitavanja će započeti, a na zaslonu će se prikazati poruka "Učitavanje..." kako biste znali da je proces u tijeku.</li>
              <li>Molimo pričekajte dok se fotografija uspješno učitaja.</li>
              </ol>

            </div>

            <div>
              <h3 className="text-lg font-semibold">Korak 4: Status Učitavanja i Završetak</h3>
              <ol className="list-decimal list-inside ml-4">
            
              <li>Gornji desni kut zaslona prikazuje status: npr. "3/5" za tri učitane fotografije od pet.</li>
              <li>Kada uspješno učitate svih pet fotografija, prikazat će se poruka: "Objavili ste maksimalan broj fotografija!"</li>
              </ol>
           
            </div>

            <div>
              <h3 className="text-lg font-semibold">Korak 5: Pregled fotografija</h3>
              <ol className="list-decimal list-inside ml-4">
            
            <li>
              Kako bi pregledali fotografije koje ste učitali klikinte na 'Pregledaj fotografije' na dnu zaslona.
            </li>
            <li>
              Otvoriti će vam se novi zaslon na kojem će biti prikazane sve Vaše fotografije. Kako bi vidjeli fotografiju u punoj veličini kliknite na željenu fotografiju.
            </li>
            <li>
              Ako želite preuzeti sve vaše fotografije kliknite na 'Preuzmi fotografije' na dnu zaslona.
             </li>
            <li>
              Kada ste završili s pregledavanjem fotografija klikom na 'Povratak na dodavanje fotografija' u vrhu zaslona vraćate se na zaslon za dodavanje fotografija.
            </li>
              </ol>
           
            </div>

            <div className="flex justify-end">
              <button
                onClick={closeModal}
                className="px-4 py-2 bg-elegance text-white rounded hover:bg-white hover:text-elegance "
              >
                Zatvori
              </button>
            </div>
          </div>
        </div>

    </div>
  );
}

export default InfoModal;
